import React from 'react';
import { Outlet } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

const ConfigEntry = () => {
    const GlobalStyles = createGlobalStyle`
    
        @import url('https://cdn.jsdelivr.net/npm/bootstrap-v4-grid-only@1.0.0/dist/bootstrap-grid.css');
    `
    return (
        <React.Fragment>
            <GlobalStyles/>
            <Outlet />
        </React.Fragment>
    );
};

export default ConfigEntry;
