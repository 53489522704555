import Button from 'elements/Button';
import Div from 'elements/Div';
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import request from 'utils/request';
import RenderSection from './RenderSection';

const Subframe = () => {
    const { id: webId } = useParams();
    const [isLoading, setIsLoading] = React.useState(true);
    const [fetchedTemplates, setFetchedTemplates] = React.useState([]);
    const [selectedTemplate, setSelectedTemplate] = React.useState(null);
    const [searchParams] = useSearchParams();
    const templateIdQuery = searchParams.get('templateId');
    const pageIdQuery = searchParams.get('pageId');
    React.useEffect(() => {
        fetchTemplates();
    }, []);
    const fetchTemplates = async () => {
        try {
            let path = ''
            const headers = {}
            if(pageIdQuery) {
                path = `/page`
                headers['web-id'] = webId
            }
            if(templateIdQuery) path= `/local_template?web_id=${webId}`
            const result = await request({ url: window.urls.web + path, headers });
            console.log(result, 'baas')
            const idToMatch = templateIdQuery ?? pageIdQuery
            if (idToMatch) {
                const foundOne = result.data.find(t => t.id === idToMatch);
                if (foundOne) setSelectedTemplate(foundOne);
            }
            setFetchedTemplates(result.data);
        } catch (e) {
            console.log('Error in Subframe', e);
        } finally {
            setIsLoading(false);
        }
    };
    React.useEffect(() => {
        if(selectedTemplate) {
            const event = new CustomEvent('subframeSelected', {
                detail: { subframe: window, templateId: selectedTemplate.id },
            });
            window.parent.dispatchEvent(event);
        }
    }, [selectedTemplate])
    return isLoading ? (
        'loading'
    ) : (
        <>
            {selectedTemplate ? (
                <RenderSection composition={templateIdQuery ? [selectedTemplate.data] : selectedTemplate.data}/>
            ) : (
                <Container>
                    <Div c="title">Choose your template</Div>
                    <Div c="templates-wrap">
                        {fetchedTemplates.map((template) => (
                            <Div onClick={()=>setSelectedTemplate(template)} c="template" key={template.id}>
                                <Div c="name">{template.name}</Div>
                            </Div>
                        ))}
                    </Div>
                </Container>
            )}
        </>
    );
};

export default Subframe;

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    & > .title {
        padding: 8px 16px;
        font-size: 20px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        text-align: center;
    }
    & > .templates-wrap {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        & > .template {
            padding: 6px 18px;
            text-align: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            cursor: pointer;
            &:first-child {
                border-top: 1px solid rgba(0, 0, 0, 0.1);
            }
            &:hover {
                background: rgba(0, 0, 0, 0.1);
            }
        }
    }
`;
