/* eslint-disable react/prop-types */
import React from 'react';
import Library from 'siro_lib';

const RenderSection = ({composition}) => {
    const stackCount = React.useRef(0);
    const [screen, setScreen] = React.useState(window.innerWidth);
    const [device, setFrameDevice] = React.useState('desktop');

    React.useEffect(() => {
        identifyScreenWidth();
        window.addEventListener('resize', () => {
            identifyScreenWidth();
        });
    }, []);

    React.useEffect(() => {
        if (screen <= 480) setFrameDevice('mobile');
        else if (screen > 480 && screen <= 768) setFrameDevice('tablet');
        else setFrameDevice('desktop');
    }, [screen]);

    const identifyScreenWidth = () => {
        setScreen(window.innerWidth);
    };

    const identifyStyle = (style) => {
        if (device === 'desktop') {
            return { ...style['desktop'] };
        } else if(device === "tablet") {
            return { ...style['desktop'], ...style['tablet'] };
        } else if (device === "mobile") {
            return {...style['desktop'], ...style['tablet'], ...style['mobile']}
        }
    };
    const getOptionsKeys = (arg) => {
        let optionString = ''
        if(arg.propData) {
            if(arg.propData.options) {
                if(arg.propData.options.defaultValue) {
                    Object.keys(arg.propData.options.defaultValue).forEach(key => {
                        optionString = optionString + key + '.'
                    })
                    return optionString.slice(0, optionString.length-1)
                }
            }
        }
        return optionString
    }

    const StackLayers = ({ type, component, children, depth, index, track, appendable, ...props }) => {
        const indexToAssign = index;
        if (!track) track = '' + index + '';
        else track = track + '-' + index;
        depth = typeof depth === 'number' ? depth + 1 : 0;
        const hasChildren = children && children.length;
        const Component = Library[type][component].default;
        const optionKeys = getOptionsKeys(Library[type][component])
        stackCount.current += 1;
        return (
            <Component
                options={props.options}
                style={identifyStyle({...props.style})}
                key={track}
                builderHelpers={{
                    'data-depth': depth,
                    'data-index': indexToAssign,
                    'data-track': track,
                    'data-options': optionKeys,
                    title: props?.name ?? component,
                    kind: 'component',
                }}
            >
                {hasChildren && children.map((child, index) => StackLayers({ ...child, track, depth, index }))}
            </Component>
        );
    };

    return (
        <React.Fragment>
            {composition ? composition.map((instance, index) => {
                return StackLayers({ ...instance, index, depth: 0 });
            }) : ''}
        </React.Fragment>
    );
};

export default RenderSection;
