export const addRipple = (e) => {
    const target = e.target;
    const circle = document.createElement('span');
    const diameter = Math.max(target.clientWidth, target.clientHeight);
    const radius = diameter / 2;
    const clientRect = target.getBoundingClientRect();

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${e.clientX - (clientRect.left + radius)}px`;
    circle.style.top = `${e.clientY - (clientRect.top + radius)}px`;
    circle.classList.add('ripple');

    const ripple = target.getElementsByClassName('ripple')[0];

    if (ripple) {
        ripple.remove();
    }

    target.appendChild(circle);
};
export const findOuterMostAppendable = (element) => {
    if (!element || element.getAttribute('data-appendable') === 'true') {
        return element;
    }
    return findOuterMostAppendable(element.parentElement);
};
export function generateId() {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let id = "";
    id += letters.charAt(Math.floor(Math.random() * letters.length));
    for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        id += characters[randomIndex];
    }
    return id;
}