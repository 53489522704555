/* eslint-disable react/prop-types */
import React from 'react';
import Library from 'siro_lib';
import { generateId } from 'utils/vanillaFunctions';

const Frame = () => {
    console.log('hehe')
    const [composition, setComposition] = React.useState([]);
    const stackCount = React.useRef(0);
    const [screen, setScreen] = React.useState(window.innerWidth);
    const [device, setFrameDevice] = React.useState('desktop');
    React.useEffect(() => {
        // SEND COMPOSITION CHANGE SIGNAL
        const event = new CustomEvent('compositionChange', { detail: { composition: [...composition] } });
        window.parent.dispatchEvent(event);
    }, [composition]);

    React.useEffect(() => {
        // SEND MOUNTED SIGNAL
        const event = new CustomEvent('frameMounted', {
            detail: { mounted: true, frame: window },
        });
        window.parent.dispatchEvent(event);

        // LISTEN FOR DATA CHANGE
        window.addEventListener('dataChange', ({ detail }) => {
            setComposition(detail);
        });

        identifyScreenWidth();
        window.addEventListener('resize', () => {
            identifyScreenWidth();
        });
    }, []);

    React.useEffect(() => {
        if (screen <= 480) setFrameDevice('mobile');
        else if (screen > 480 && screen <= 768) setFrameDevice('tablet');
        else setFrameDevice('desktop');
    }, [screen]);

    const identifyScreenWidth = () => {
        // SEND FRAME SCREEN SIGNAL
        const event = new CustomEvent('screenChange', { detail: { screenWidth: window.innerWidth } });
        window.parent.dispatchEvent(event);
        setScreen(window.innerWidth);
    };

    const identifyStyle = (style) => {
        if (device === 'desktop') {
            return { ...style['desktop'] };
        } else if(device === "tablet") {
            return { ...style['desktop'], ...style['tablet'] };
        } else if (device === "mobile") {
            return {...style['desktop'], ...style['tablet'], ...style['mobile']}
        }
    };

    const StackLayers = ({ type, component, children, depth, index, track, appendable, ...props }) => {
        const indexToAssign = index;
        if (!track) track = '' + index + '';
        else track = track + '-' + index;
        depth = typeof depth === 'number' ? depth + 1 : 0;
        const hasChildren = children && children.length;
        console.log(Library[type], 'type', component)
        // console.log(Library[type][component], 'type')
        const Component = Library[type][component].default;
        const propTypes = Library[type][component].propData.options.defaultValue;
        const richText = Object.keys(propTypes).find((p) => propTypes[p].type === 'richtext');
        stackCount.current += 1;
        return (
            <Component
                contenthook={generateId()}
                cases={props.cases}
                events={props.events}
                state={props.state}
                options={props.options}
                // style={screen >= 906 ? {...props.style} : {...props.style, paddingLeft: '45px'}}
                style={identifyStyle({...props.style})}
                key={track}
                builderHelpers={{
                    'data-depth': depth,
                    'data-index': indexToAssign,
                    'data-track': track,
                    'data-appendable': appendable ? true : '',
                    'data-richtext': richText ? true : false,
                    'data-richtextfield': richText,
                    draggable: true,
                    title: props?.name ?? component,
                    kind: 'component',
                }}
            >
                {hasChildren && children.map((child, index) => StackLayers({ ...child, track, depth, index }))}
            </Component>
        );
    };

    return (
        <React.Fragment>
            {composition.map((instance, index) => {
                return StackLayers({ ...instance, index, depth: 0 });
            })}
        </React.Fragment>
    );
};

export default Frame;
