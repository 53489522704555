import Frame from 'Frame';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import ConfigEntry from 'router-entries/ConfigEntry';
import Subframe from 'Subframe';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <Routes>
            <Route path="" element={<ConfigEntry />}>
                <Route path="/" element={<Frame />} />
                <Route path="/subframe/:id" element={<Subframe />} />
            </Route>
        </Routes>
    </BrowserRouter>
);